const routes = {
  index: '/',
  auth: {
    login: '/auth/login'
  },
  access: {
    index: '/access',
    users: {
      index: '/access/users/list',
      add: '/access/users/add'
    },
    roles: {
      index: '/access/roles/list',
      add: '/access/roles/add'
    },
    allowedIpAddresses: {
      index: '/access/allowed-ip-addresses/list',
      add: '/access/allowed-ip-addresses/add'
    }
  },
  customers: {
    index: '/customers/list',
    add: '/customers/add'
  },
  debitCards: {
    index: '/debit-cards/list',
    add: '/debit-cards/add'
  },
  systemEvents: {
    index: '/system-events'
  },
  changeAudit: {
    index: '/change-audit/list'
  }
};

export default routes;
