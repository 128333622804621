const apiBaseUrl = process.env.NEXT_PUBLIC_API_URL;

const apiUrls = {
  apiBaseURL: process.env.NEXT_PUBLIC_API_URL,
  auth: {
    login: '/connect/token',
    sendAccessCode: '/api/Account/send-accesscode',
    submitAccessCode: '/api/Account/accesscode'
  },
  policies: {
    list: '/api/Policies'
  },
  authClient: '/api/AuthorizationClient/create',
  customers: {
    odata: '/odata/Customers',
    list: '/api/Customers',
    item: (customerId) => `/api/Customers/${customerId}`,
    byToken: (tokenId) => `/api/Customers/token/${tokenId}`
  },
  debitCards: {
    odata: '/odata/DebitCards',
    list: '/api/DebitCards',
    item: (cardId) => `/api/DebitCards/${cardId}`,
    byToken: (tokenId) => `/api/DebitCards/token/${tokenId}`,
    getCardTypes: '/api/DebitCards/card-types'
  },
  users: {
    odata: '/odata/Users',
    list: '/api/Users',
    item: (userId) => `/api/Users/${userId}`,
    changePassword: (userId) => `/api/Users/${userId}/password`,
    current: '/api/Users/current',
    lock: (userId) => `/api/Users/${userId}/lock`,
    unlock: (userId) => `/api/Users/${userId}/unlock`
  },
  roles: {
    odata: '/odata/UserGroups',
    list: '/api/UserGroups',
    item: (roleId) => `/api/UserGroups/${roleId}`
  },
  allowedIpAddresses: {
    list: '/api/IpAddresses',
    details: (id) => `/api/IpAddresses/${id}`
  },
  loggedEvents: {
    odata: '/odata/LoggedEvents',
    formData: (id) => `/odata/LoggedEvents(${id})/FormData`
  },
  changeAudit: {
    odata: 'odata/ChangeAudits',
    entities: 'api/ChangeAudits/entities',
    properties: 'api/ChangeAudits/properties',
    actions: 'api/ChangeAudits/actions'
  },
  healthCheck: '/api/healthz'
};

export { apiUrls, apiBaseUrl };
