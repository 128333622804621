export const MESSAGES_ERROR = {
  ERR001: 'The mandatory field is not filled',
  ERR002: 'A user with such email already exists',
  ERR003: 'Invalid email address',
  // ERR004: '${max} character maximum',
  ERR005: 'Passwords do not match',
  ERR006: 'Your username and/or password do not match',
  ERR007: 'You need to agree to the terms and conditions to proceed',
  ERR008: 'The password is incorrect, please try again',
  ERR009:
    'Your account was blocked, please contact our customer service for more information',
  ERR010: 'Your code has expired, please request a new one'
};
